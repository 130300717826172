@import "../../mixins.scss";

.working-hours-section {
  background: url("../../assets/images/working-hours-bg.webp") center center
    no-repeat;
  background-size: cover;
  padding: 0 10%;
  aspect-ratio: 2.6/1;
  @include flex();
  flex-direction: column;
  align-items: flex-start;
  h3 {
    @include heading1();
    font-size: 6rem;
    color: var(--primary-text);
    max-width: 250px;
  }
  p {
    @include para($fw: 400);
    font-size: 2rem;
    color: var(--primary-text);
    margin: 5px 0;
  }
  button {
    margin-top: 10px;
    @include button($w: 240px);
  }

  @media (max-width: 700px) {
    padding: 0 5%;

    h3 {
      font-size: 2.5rem;
      max-width: 350px;
    }

    p {
      font-size: 1.6rem;
      margin: 2px 0;
    }

    button {
      @include button($w: 200px);
      margin: 15px unset;

      a {
        font-size: 1.4rem;
        padding: 6px;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0 5%;

    h3 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1.2rem;
      margin: 2px 0;
    }

    button {
      @include button($w: 150px);
      margin: 15px unset;

      a {
        font-size: 1.2rem;
        padding: 2px 0;
      }
    }
  }
}
